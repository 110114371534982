var Flickity = require('flickity');
import 'flickity/css/flickity.css';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";


require('flickity-imagesloaded');

export const slidehows = () => {

    const slideshows = document.querySelectorAll(".image-item");
    const projectsLineItems = document.querySelectorAll('.project-item');

    projectsLineItems.forEach((elem) => {

        elem.addEventListener('mouseenter', function () {
            const elemUid = elem.dataset.uid;
            const slideshow = document.querySelector('.image-item[data-uid="' + elemUid + '"]');
            slideshows.forEach((elem) => {
                elem.classList.remove('selected');
            });
            slideshow.classList.add('selected');
        });
    });


    var coll = document.querySelectorAll(".collapsible");
    var flkty;
    let flktyInstances = [];

    coll.forEach((elem) => {

        const closeBtn = elem?.querySelector('.cross');

        elem.addEventListener("click", function (e) {

            function destroyAllFlickity() {
                console.log('flckty destroyed');
                flktyInstances.forEach((flkty) => {
                    if(flkty){
                        console.log(flkty);
                        flkty.destroy();
                    }
                });
                flktyInstances = [];  // Clear the array after destruction
            }

            const htmlElement = document.documentElement;
            const styles = getComputedStyle(htmlElement);
            const vhValue = parseFloat(styles.getPropertyValue('--vh').trim()) * 100;
            const hhValue = parseFloat(styles.getPropertyValue('--hh').trim());
            const ehValue = parseFloat(elem.scrollHeight);
            var contentHeight = vhValue - hhValue - ehValue + "px";
            var content = this.nextElementSibling;


            if (parseFloat(contentHeight) < content.scrollHeight) {
                contentHeight = content.scrollHeight + "px";
            }

            if (content.style.minHeight) {
                this.classList.remove("active");
                content.style.minHeight = null;
                content.style.maxHeight = null;
                destroyAllFlickity();
            } else {

                coll.forEach((elem) => {
                    var content = elem.nextElementSibling;
                    elem.classList.remove("active");
                    if (content.style.minHeight) {
                        content.style.minHeight = null;
                        content.style.maxHeight = null;
                        destroyAllFlickity();
                    }
                });
                this.classList.add("active");
                content.style.minHeight = contentHeight;
                content.style.maxHeight = null;

                const slideshowId = elem.parentElement.dataset.uid;
                const slideshows = document.querySelectorAll(".carousel[data-uid='" + slideshowId + "']");

                slideshows.forEach((slideshow) =>{
                    flkty = new Flickity(slideshow, {
                        cellAlign: 'left',
                        contain: true,
                        lazyLoad: 2,
                        autoPlay: false,
                        fade: true,
                        wrapAround: true,
                        selectedAttraction: 1,
                        pauseAutoPlayOnHover: false,
                        friction: 1,
                        pageDots: false,
                        prevNextButtons: true,
                    });

                    flktyInstances.push(flkty);
                });

                if(!elem.classList.contains('active')){
                    destroyAllFlickity();
                }

            }

            e.preventDefault();
            const pageContainer = document.querySelector('.page-container .fp-overflow');
            gsap.registerPlugin(ScrollToPlugin);
            setTimeout(function (){
                gsap.to(pageContainer, {
                    duration: 0.3,
                    scrollTo: elem.parentElement,
                    onComplete: function() {
                    }
                });
            }, 200);

        });
    });
}