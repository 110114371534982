const menuItems = document.querySelectorAll('nav li a');

menuItems.forEach((elem) =>{

    elem.addEventListener('click', function (){
       menuItems.forEach((elem) =>{
          elem.classList.remove('active');
       });
       elem.classList.add('active');
    });
});